import styled from 'styled-components';

import { BaseButton } from './BaseButton';

export const WhiteButton = styled(BaseButton)`
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.grey.dark};
  border: 0;
  padding: 14px 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

  .icon {
    color: ${(props) => props.theme.colors.white};
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.grey[800]};
    color: ${(props) => props.theme.colors.white};

    .icon {
      color: ${(props) => props.theme.colors.white};
    }
  }

  .loading-spinner {
    path {
      fill: ${(props) => props.theme.colors.white};
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-bottom: 12px;
    padding-top: 12px;
  }
`;
