import LoaderImage from '@/public/icons/loading-spinner.svg';

import { LoaderWrapper } from './styles';

export default function Loader() {
  return (
    <LoaderWrapper data-testid="button-loader">
      <LoaderImage className="loading-spinner" />
    </LoaderWrapper>
  );
}
