import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import config from './config';

const Container = styled.div`
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: ${(p) => `${config(p).containerWidth}px`};
  padding-right: ${(p) => `${config(p).outerMargin}px`};
  padding-left: ${(p) => `${config(p).outerMargin}px`};

  ${(props) =>
    props.small &&
    css`
      max-width: 1050px;
    `};
  ${(props) =>
    props.large &&
    css`
      max-width: 1366px;
    `};

  ${(props) =>
    props.centered &&
    css`
      text-align: center;
    `};

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-right: ${(p) => `${config(p).mobileOuterMargin}px`};
    padding-left: ${(p) => `${config(p).mobileOuterMargin}px`};
  }
`;

Container.displayName = 'Container';

Container.propTypes = {
  children: PropTypes.node,
};

export default Container;
