import ReactMarkdown from 'react-markdown';
import styled, { css } from 'styled-components';

const sharedStyles = css`
  font-family: ${(props) => props.theme.typography.family.title};
  font-size: 45px;
  line-height: 56px;
  font-weight: 700;
  padding-bottom: 32px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 30px;
    line-height: 40px;
    padding-bottom: 24px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 25px;
    line-height: 32px;
  }

  ${(props) =>
    props.centered &&
    css`
      text-align: center;
    `};
`;

export const H1 = styled.h1`
  ${sharedStyles}
`;

export const H2 = styled.h2`
  ${sharedStyles}
`;

export const H3 = styled.h3`
  ${sharedStyles}
`;

export const H4 = styled.h4`
  ${sharedStyles}
  font-size: 20px;
  line-height: 24px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 20px;
    line-height: 24px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

export const H6 = styled.h6`
  ${sharedStyles}
`;

export default function Title({ allowedElements = ['strong'], as, children, className, ...rest }) {
  function renderComponent(Component) {
    return (
      <Component className={className} {...rest}>
        <ReactMarkdown allowedElements={allowedElements} unwrapDisallowed>
          {children}
        </ReactMarkdown>
      </Component>
    );
  }

  switch (as) {
    case 'h1':
      return renderComponent(H1);
    case 'h2':
      return renderComponent(H2);
    case 'h3':
      return renderComponent(H3);
    case 'h4':
      return renderComponent(H4);
    case 'h6':
      return renderComponent(H6);
    default:
      return renderComponent(H2);
  }
}
