import Link from 'next/link';

import Loader from './Loader';
import { ButtonStyle } from './styles';

export function BaseButton({ className, type, children, href, ...props }) {
  if (href) {
    return (
      <Link href={href} passHref legacyBehavior>
        <ButtonStyle {...props} className={className} as="a">
          {children}
        </ButtonStyle>
      </Link>
    );
  }

  return (
    <ButtonStyle {...props} className={className} type={type ?? 'button'}>
      {props?.loading && <Loader />}
      {children}
    </ButtonStyle>
  );
}
