import styled, { css } from 'styled-components';

export const ButtonStyle = styled.button`
  display: inline-block;
  position: relative;
  font-family: ${(props) => props.theme.typography.family.title};
  border-radius: 51px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  padding: 13px 32px;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 11px;
    padding-top: 11px;
  }

  ${(props) =>
    props.loading &&
    css`
      color: transparent !important;
      pointer-events: none;
      opacity: 0.8;
      cursor: pointer;
    `}

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.7;
      cursor: pointer;
    `}

  ${(props) =>
    props.small &&
    css`
      padding: 11px 23px;
      font-size: 16px;
    `}

  ${(props) =>
    props.bigger &&
    css`
      padding: 24px 30px;
      font-size: 22px;
      font-weight: 600;

      @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
        font-size: 16px;
        padding: 16px 30px;
      }
    `}
  
  ${(props) =>
    props.fullwidth &&
    css`
      width: 100%;
      text-align: center;
    `}
  
  &:hover {
    background-color: ${(props) => props.theme.colors.primary.dark};
    color: ${(props) => props.theme.colors.white};
  }
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(359deg);
    }
  }

  .loading-spinner {
    animation: rotation 2.5s infinite linear;

    path {
      fill: ${(props) => props.theme.colors.white};
    }
  }
`;
