'use client'; // Error components must be Client Components

import * as Sentry from '@sentry/nextjs';
import { useEffect } from 'react';
import styled from 'styled-components';

import { PrimaryButton } from '@/components/ui/Button';
import { Col, Container, Row } from '@/components/ui/flexbox';
import { H3 } from '@/components/ui/Title';

export const metadata = {
  title: 'Sword Health | Error',
};

export default function Error({ error, reset }) {
  useEffect(() => {
    // Log the error to an error reporting service
    Sentry.captureException(error);
    console.error(error);
  }, [error]);

  return (
    <StyledContainer>
      <Row>
        <Col xs={12}>
          <H3>Something went wrong!</H3>

          <PrimaryButton
            onClick={
              // Attempt to recover by trying to re-render the segment
              () => reset()
            }
          >
            Try again
          </PrimaryButton>
        </Col>
      </Row>
    </StyledContainer>
  );
}

const StyledContainer = styled(Container)`
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;
