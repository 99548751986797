import styled, { css } from 'styled-components';

const Label = styled.div`
  font-size: 16px;
  line-height: 28px;
  opacity: 0.8;
  padding-bottom: 32px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-bottom: 24px;
  }
  ${(props) =>
    props.$medium &&
    css`
      opacity: unset;
      font-size: 18px;
      font-weight: 500;
      line-height: 32px;
    `};
`;

export default Label;
