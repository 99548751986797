import styled from 'styled-components';

import { BaseButton } from './BaseButton';

export const PrimaryButton = styled(BaseButton)`
  background-color: ${(props) => props.theme.colors.primary.default};
  color: ${(props) => props.theme.colors.white};

  .loading-spinner {
    path {
      fill: ${(props) => props.theme.colors.white};
    }
  }

  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.colors.primary.dark};
    color: ${(props) => props.theme.colors.white};
  }
`;
